import _ from 'lodash'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { isComplexAddressInnerField, isInputField } from '../utils'
import { FieldDataForCollectionActions } from './api'
import { fetcher } from '../../../utils/utils'

export const shouldAddFieldToCollection = ({ role, fieldType }: FieldDataForCollectionActions) =>
  (isInputField(role) || isComplexAddressInnerField(role)) && allowCollectionSync(fieldType)

export const filterFieldsToCollection = <T extends FieldDataForCollectionActions>(
  fields: T[],
): T[] => _.filter(fields, (field) => shouldAddFieldToCollection(field))

const WIX_DATA_CONCURRENT_EDITING_ERROR = 'WDE0300:'

export const retryOnConcurrentEditingErrors = (
  { absorbException }: { absorbException: boolean } = { absorbException: false },
) => (_target: any, _funcName: string, decorator: PropertyDescriptor) => {
  const originalMethod = decorator.value
  decorator.value = async function (...args) {
    try {
      this.fedopsLogger.interactionStarted('interact-with-collections-api')

      const res = await originalMethod.apply(this, args)

      this.fedopsLogger.interactionEnded('interact-with-collections-api')
      return res
    } catch (err) {
      if (
        err?.message?.startsWith(WIX_DATA_CONCURRENT_EDITING_ERROR)
      ) {
        const res = fetcher<ReturnType<typeof originalMethod>>()
        setTimeout(async () => {
          try {
            const tempRes = await originalMethod.apply(this, args)

            this.fedopsLogger.interactionEnded('interact-with-collections-api')
            res.resolveData(tempRes)
          } catch (e) {
            if (absorbException) {
              res.resolveData()
            } else {
              res.rejectData(e)
            }
          }
        }, 500)
        return res.getData
      } else {
        if (absorbException) {
          return Promise.resolve()
        } else {
          throw err
        }
      }
    }
  }
}
